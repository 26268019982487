.title {
    color: #1487d4;
    font-size: x-large;
    margin-bottom: 20px;
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
}

.titleNews {
    color: #1487d4;
    font-size: large;
    margin-bottom: 20px;
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: 600;
}

.bodyNews {
    text-indent: 40px;
    font-weight:600;
    margin-bottom: 10px;
}

.rowNews {

    padding: 2%;
    border-radius: 15px;
}

.imgNews {
    display: block;
    height: auto;
    margin-bottom: 20px;
}

.box {
    background-color: white;
    padding: 2%;
    border-radius: 15px;
}

.bon {
    padding: 3%;
}

.right {
    margin-right: 2%;
    margin-bottom: 2%;
}

.bonBorder {
    border-radius: 10%;
}

.facebook {
    border: none;
    overflow: hidden;
    margin-top: 10px;
    min-width: 340px;
}

.kontakt {
    text-align: center;
}