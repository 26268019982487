.body {
  background-color: #ffffff;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App {
  height: 100%;
}

.headerBlackLine {
  background-color: black;
  width: 100%;
  height: 10px;
}

.headerBlackTop {
  background-color: black;
  color: white;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  width: 100%;
  padding-top: 10px;
  margin-bottom: -15px;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.white {
  color: white;
}


.headerBlackMenu {
  background-color: black;
  padding: 1%;
  color: white;
  text-align: center;
  justify-content: center;
}

.headerBlackMenu a {
  color: white;
  padding: 1.5%;
  text-decoration: none;
}

.headerBlackMenu a:hover {
  color: rgb(167, 167, 167);
}

.headerBlackMenu a:active {
  color: white;
}

.bodyContainer {
  background-color: rgba(0, 0, 0, 0.045);
  width: 90vw;
  height: 100%;
  position: relative;
  margin: auto;
  padding: 2%;
  padding-bottom: 3%;
}

.bars {
  background-color: black;
  width: 100vw;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.barsText {
  color: #fef510;
  margin: 5px;
  display: inline-block;
  border: 2px solid #fef510;
  border-radius: 10px;
  width: 100px;

}

.barsNone {
  display: none;
}

.barsClose {
  background-image: url("./images/close.png");
  background-size: 27px;
  position: absolute;
  top: 12px;
  right: 5px;
  width: 27px;
  height: 27px;
  z-index: 999;
}

.footer {
  background-color: black;
  width: 100%;
  padding: 1%;
  color: white;
  text-align: center;
  justify-content: center;
}

.footer a {
  color: white;
  text-decoration: none;
}

.facebook {
  display: inline;
  font-size: 25px;
  margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
  body {
    display: block;
  }

  .bodyContainer {
    height: 100%;
    display: flex;
    position: relative;
  }

  .headerBlackMenu {
    background-color: rgba(0, 0, 0, 0.95);
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    transform: translate3d(-100vw, 0, 0);
    width: 100vw;
    height: 100%;
  }

  .translateMenu {
    z-index: 998;
    transform: translate3d(0, 0, 0);
  }

  .headerBlackMenu a {
    font-size: 1.3rem;
    color: white;
    margin-top: 40px;
    padding: 1%;
    text-decoration: none;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.521);
    border-radius: 10px;
    margin-left: 20%;
    margin-right: 20%;
  }

  .bodyContainer {
    width: 100vw;
  }

  .facebook a {
    display: block !important;
    font-size: 40px !important;
    border: none !important;
    margin: 0 !important;
    padding: 0;
    margin-top: -30px !important;
  }
}