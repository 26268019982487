.container {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    color: #232323;
    font-weight: 300;
}

.category {
    max-width: 25rem !important;
    padding: 0 0.75rem;
    margin: 1rem 1rem;
    border: 1px solid #da3545;
    transition: all .3s ease;
    text-align: center;
}

.category a {
    text-decoration: none;
}

.category:hover {
    -webkit-box-shadow: 4px 3px 57px -9px rgba(66, 68, 90, 1);
    -moz-box-shadow: 4px 3px 57px -9px rgba(66, 68, 90, 1);
    box-shadow: 4px 3px 57px -9px rgba(66, 68, 90, 1);
}

.categoryIcon {
    font-size: 9.25rem;
    height: 11rem;
    color: rgb(255, 0, 0);
    margin-top: auto;
    margin-bottom: 2rem;
}

.categoryTitle {
    line-height: 2rem;
    padding: 0 0 1rem 0;
    margin: 0.75rem 0 1rem;
    font-size: 1.625rem;
    color: #232323;
    font-weight: normal;
    letter-spacing: -.005em;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
    height: 6rem;
}

.categoryTitle::after {
    content: "";
    background: rgba(255, 0, 0, 0.85);
    width: 11rem;
    height: 0.1875rem;
    margin: 0 0 -0.125rem -3.8125rem;
    position: absolute;
    bottom: 0;
    left: 40%;
    z-index: 1;
}

.buttonMore {
    maring-top: 0.4rem;
    margin-bottom: 0.8rem;
    text-transform: uppercase;

}