.instruktorzy {
    text-align: center;
    font-size: large;
}

.instruktorBox{
    margin-top: 30px;
}

.imieInstruktora{
    color:red;
    font-weight: 800;
    font-size: 1.6rem;
}
