.logo {
    background-color: #fef510;
    width: 100%;
    height: auto;
    display:inline-flex;
}

.logo img {
    display: block;
    max-width:400px;
    max-height:150px;
    width: auto;
    height: auto;
    margin-left: 15%;
    margin-bottom: auto;
    margin-top: auto;
}

.slidebar {
    width: 30%;
    height: auto;
    position: relative;
    margin-left: auto;
    margin-right: 15%;
    padding-top: 1%;
    padding-bottom: 1%;
    float: right;
    text-align: center; 
}


@media only screen and (max-width: 768px) {

    .logo {
        display: block;
        width: 100vw;
        height: auto;
    }

    .logo img{
        display: block;
        width: 90%;
        padding-top: 2%;
        padding-bottom: 2%;
        margin: auto;
        height: auto;
    }
    .slidebar {
        background-color: #ffffff;
        display: block;
        margin: auto;
        padding: auto;
        position: relative;
        width: 100vw;
        font-size: 1em;
    }

}