.catTitle {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.imgCategory{
    width: 100%;
    margin: auto;
}

.categoryPage{
    font-size: 1.3rem;
    width: 100%;
}

.brakeLine {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #b6b6b6;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.warning {
    color: red;
    font-weight: bold;
    font-size: 2rem;
}

.categoryIconDesc {
    text-align: center;
    vertical-align: middle;
    font-size: 10rem;
    height: 11rem;
    margin-bottom: 5rem;
}

.right {
    width: auto;
}